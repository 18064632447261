export const roleNameList = ['sedgewick', 'ERP-operator', 'admin', 'cn cs', 'sr.rep(cn)', 'us cs', 'sr.rep(us)', 'Sedgewick Supervisor'] // 用户角色：0第三方客服、1erp用户、2管理员、3中国区客服专员、4中国区客服主管，5美国区客服专员，6美国区客服主管

export const classificationList = [
  'Check status/urge shipment',
  'Check/confirm information',
  'Change address/model/color',
  'Refund',
  'Ask for assistance-Upload pictures',
  'Replacement issues (Wrong item/color, arrived damaged)',
  'Duplicate unit',
  'Other'
]