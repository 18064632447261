<template>
  <a-modal
    :title="title"
    :visible="visible"
    :confirm-loading="confirmLoading"
    @ok="handleOk"
    @cancel="handleCancel"
    :destroyOnClose="true"
  >
    <a-form-model
      ref="ruleForm"
      :model="ruleForm"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="Account Name" prop="username">
        <a-input v-model.trim="ruleForm.username" placeholder="Please enter (100 characters at most)" :maxLength="100"></a-input>
      </a-form-model-item>
      <a-form-model-item label="Account Role" prop="role">
        <a-select v-model="ruleForm.role" placeholder="please select">
          <a-select-option :value="index" v-for="(item, index) in roleNameList" :key="index">{{item}}</a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="Initialize Password" prop="initializePassword" v-if="id">
        <a-radio-group v-model="ruleForm.initializePassword">
          <a-radio :value="0">NO</a-radio>
          <a-radio :value="1">YES</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <!-- <p>Initialization password: 123456</p> -->
      <a-form-model-item label="Account status" prop="state" v-if="id">
        <a-radio-group v-model="ruleForm.state">
          <a-radio :value="0">Normal</a-radio>
          <a-radio :value="1">Disable</a-radio>
        </a-radio-group>
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { roleNameList } from '@/assets/js/listData.js'

export default {
  data () {
    return {
      id: null,
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
      title: 'Create Account',
      visible: false,
      confirmLoading: false,
      ruleForm: {
        username: '',
        role: undefined,
        initializePassword: 0,
        state: 0
      },
      rules: {
        username: [
          { required: true, message: 'Please enter', trigger: 'blur' }
        ],
        role: [
          { required: true, message: 'Please select', trigger: 'change' }
        ]
      },
      roleNameList
    }
  },
  methods: {
    show (data) {
      this.title = 'Create Account'
      if (data) {
        this.title = 'Edit Account'
        this.id = data.id
        const keys = Object.keys(this.ruleForm)
        keys.forEach((item) => {
          if (data[item] || data[item] === 0) {
            this.ruleForm[item] = data[item]
          }
        })
      }
      this.visible = true
    },
    async addTicketAdminAccount () {
      try {
        const res = await this.$http.post('/platform/admin/ticketAdminAccount/v1/addTicketAdminAccount', {...this.ruleForm})
        if (res.code === 0) {
          this.$emit('handleSuccess')
          this.$message.success('Create account success');
          this.handleCancel()
        }
      } catch (error) {
        //
      }
    },
    async updateTicketAdminAccount () {
      try {
        const res = await this.$http.post('/platform/admin/ticketAdminAccount/v1/updateTicketAdminAccount', {...this.ruleForm, id: this.id})
        if (res.code === 0) {
            this.$emit('handleSuccess')
          this.$message.success('Update account success');
          this.handleCancel()
        }
      } catch (error) {
        //
      }
      
    },
    handleOk () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.id ? this.updateTicketAdminAccount() : this.addTicketAdminAccount()
        } else {
          return false;
        }
      });
    },
    handleCancel () {
      this.id = null
      this.ruleForm = {
        username: '',
        role: undefined,
        initializePassword: 0,
        state: 0
      }
      this.visible = false
    }
  }
}
</script>