var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mainPage"},[_c('p',{staticClass:"pageTitle"},[_vm._v("Account List")]),_c('div',{staticClass:"pageWrap"},[_c('a-form',{attrs:{"layout":"inline"}},[_c('a-form-item',{attrs:{"label":"Account Name"}},[_c('a-input',{attrs:{"placeholder":"Please enter"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"username"}})],1),_c('a-form-item',[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.getData}},[_vm._v("Search")])],1),_c('a-form-item',[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleEdit()}}},[_vm._v("Create Account")])],1)],1),_c('a-table',{staticClass:"mt24",attrs:{"data-source":_vm.tableData,"columns":_vm.columns,"loading":_vm.loading,"pagination":{
        defaultCurrent: 1,
        total: _vm.total,
        pageSize: _vm.pageSize,
        current: _vm.currentPage,
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total) => `total ${total}`,
        pageSizeOptions: ['5', '10', '20', '30', '50']
      }},on:{"change":_vm.handleChange},scopedSlots:_vm._u([{key:"action",fn:function(text, record){return _c('span',{},[_c('a-button',{attrs:{"type":"link","block":""},on:{"click":function($event){return _vm.handleEdit(record)}}},[_vm._v("Edit")])],1)}}])})],1),_c('edit-account-modal',{ref:"editAccountModal",on:{"handleSuccess":_vm.getData}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }