<template>
  <div class="mainPage">
    <p class="pageTitle">Account List</p>
    <div class="pageWrap">
      <a-form layout="inline">
        <a-form-item label="Account Name">
          <a-input placeholder="Please enter" v-model.trim="username"></a-input>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="getData">Search</a-button>
        </a-form-item>
        <a-form-item>
          <a-button type="primary" @click="handleEdit()">Create Account</a-button>
        </a-form-item>
      </a-form>

      <a-table 
        :data-source="tableData"
        :columns="columns"
        @change="handleChange"
        :loading="loading"
        :pagination="{
          defaultCurrent: 1,
          total,
          pageSize,
          current: currentPage,
          showSizeChanger: true,
          showQuickJumper: true,
          showTotal: (total) => `total ${total}`,
          pageSizeOptions: ['5', '10', '20', '30', '50']
        }"
        class="mt24">
        <span slot="action" slot-scope="text, record">
          <a-button type="link" block @click="handleEdit(record)">Edit</a-button>
        </span>
      </a-table>
    </div>
    <edit-account-modal ref="editAccountModal" @handleSuccess="getData"></edit-account-modal>
  </div>
</template>

<script>
import { timeFormatUSA } from '@/utils/utils.js'
import { roleNameList } from '@/assets/js/listData.js'
import EditAccountModal from './components/EditAccountModal.vue'

const columns = [
    {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
  },
  {
    title: 'Account Name',
    dataIndex: 'username',
    key: 'username',
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
    customRender: (text) => {
      return roleNameList[text]
    }
  },
  {
    title: 'State',
    dataIndex: 'state',
    key: 'state',
    customRender: (text) => { 
      return text === 0 ? 'Normal' : 'Disable'
    }
  },
  {
    title: 'Last login time',
    dataIndex: 'lastLoginTime',
    key: 'lastLoginTime',
    customRender: (text) => { 
      return timeFormatUSA(text) || '-'
    }
  },
  {
    title: 'Creation time',
    dataIndex: 'createTime',
    key: 'createTime',
    customRender: (text) => { 
      return timeFormatUSA(text)
    }
  },
  {
    title: 'Operation',
    dataIndex: 'Operation',
    key: 'Operation',
    scopedSlots: { customRender: 'action' }
  }
]
export default {
  components: { EditAccountModal },
  data () {
    return {
      username: '',
      currentPage: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      loading: false,
      columns
    }
  },
  created () { 
    this.getData()
  },
  methods: {
    handleEdit (item) { 
      this.$refs.editAccountModal.show(item)
    },
    handleChange (pagination) {
      this.currentPage = pagination.current
      this.pageSize = pagination.pageSize
      this.getData()
    },
    async getData () {
      this.loading = true
      try {
        const {username, pageSize,  currentPage} = this
        const res = await this.$http.post('/platform/admin/ticketAdminAccount/v1/pageTicketAdminAccount', { username, pageSize, currentPage })
        this.tableData = res.data.accountList
        this.total = res.data.totalRecord
      } catch (error) {
        //
      } finally { 
        this.loading = false
      }
    }
  }
}
</script>